import { TripStatus } from '../../interfaces/trip-status';
import { DispatchStop } from '../../interfaces/dispatch-stop';
import { DispatchTrip } from '../../interfaces/dispatch-trip';

export function tripsOverlap(trip1: DispatchTrip, trip2: DispatchTrip) {
  return (
    (trip1.scheduledStartDateTime < trip2.scheduledEndDateTime &&
      trip1.scheduledEndDateTime > trip2.scheduledStartDateTime) ||
    (trip2.scheduledStartDateTime < trip1.scheduledEndDateTime &&
      trip2.scheduledEndDateTime > trip1.scheduledStartDateTime)
  );
}

export function validateDatesOutsideTimeFrame(
  tripStartDate: Date,
  tripEndDate: Date,
  validationStartDate: Date,
  validationEndDate: Date,
) {
  if (!tripStartDate || !tripEndDate) {
    return true;
  }
  return (
    (tripStartDate?.getTime() < validationStartDate?.getTime() &&
      tripEndDate?.getTime() < validationStartDate?.getTime()) ||
    (tripStartDate?.getTime() > validationEndDate?.getTime() && tripEndDate?.getTime() > validationEndDate?.getTime())
  );
}

export function getTripDates(trip: DispatchTrip) {
  let tripStartDate = trip.scheduledStartDateTime;
  const hoursLate = calculateLateTime(trip.stops);
  let tripEndDate: Date;
  if (trip.status === TripStatus.InTransit || trip.status === TripStatus.Delivered) {
    if (trip.actualEndDateTime) {
      tripEndDate = new Date(trip.actualEndDateTime);
    } else {
      tripEndDate = new Date(trip.scheduledEndDateTime);
      tripEndDate.setHours(tripEndDate.getHours() + hoursLate);
    }
  } else {
    tripEndDate = trip.scheduledEndDateTime;
  }

  tripStartDate = new Date(tripStartDate);
  tripEndDate = new Date(tripEndDate);
  return {
    tripStartDate: new Date(tripStartDate),
    tripEndDate: new Date(tripEndDate),
  };
}

export function getTripDatesWithTimes(trip: DispatchTrip) {
  const { tripStartDate, tripEndDate } = getTripDates(trip);
  return {
    tripStartDate: tripStartDate?.getTime(),
    tripEndDate: tripEndDate?.getTime(),
  };
}

export function getMonday(date: Date): Date {
  const day = date.getDay();
  const diff = (day === 0 ? -6 : 1) - day;
  const monday = new Date(date);
  monday.setDate(date.getDate() + diff);
  monday.setHours(0);
  monday.setMinutes(0);
  monday.setSeconds(0);
  monday.setMilliseconds(0);
  return monday;
}

export function getSunday(date: Date): Date {
  const day = date.getDay();
  const diff = day === 0 ? 0 : 7 - day;
  const sunday = new Date(date);
  sunday.setDate(date.getDate() + diff);
  sunday.setHours(23);
  sunday.setMinutes(59);
  sunday.setSeconds(59);
  sunday.setMilliseconds(999);
  return sunday;
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const calculateOverlappingTrips = (trips: DispatchTrip[]) => {
  let maxOverlaps = 1;
  for (let i = 0; i < trips.length; i++) {
    let overlaps = 1;
    for (let j = 0; j < trips.length; j++) {
      if (i !== j && tripsOverlap(trips[i], trips[j])) {
        overlaps++;
      }
    }
    if (overlaps > maxOverlaps) {
      maxOverlaps = overlaps;
    }
  }
  return maxOverlaps;
};

export const calculateLateTime = (allStops: DispatchStop[]): number => {
  if (!allStops || allStops.length === 0) return 0;

  let sortedStops = allStops.toSorted((a, b) => {
    const arrivalTimeComparison = new Date(a?.arrivalTime)?.getTime() - new Date(b?.arrivalTime)?.getTime();
    if (arrivalTimeComparison !== 0) {
      return arrivalTimeComparison;
    }

    return new Date(a?.scheduledStartDate)?.getTime() - new Date(b?.scheduledStartDate)?.getTime();
  });

  const latestStop = sortedStops[sortedStops.length - 1];
  const arrivalUnixDateTime = new Date(latestStop?.arrivalTime).getTime();
  const scheduledUnixStartDateTime = latestStop?.scheduledStartDate
    ? new Date(latestStop?.scheduledStartDate).getTime()
    : new Date(latestStop?.dateTime).getTime();

  if (
    latestStop.arrivalTime !== null &&
    latestStop.arrivalTime !== undefined &&
    arrivalUnixDateTime > scheduledUnixStartDateTime
  ) {
    const hoursLate = (arrivalUnixDateTime - scheduledUnixStartDateTime) / (1000 * 60 * 60);
    return Math.round(hoursLate * 2) / 2;
  }

  return 0;
};
