import { ClientBankInfo } from 'rtspro-financial-core-module';
import Service from './service';
import { AxiosResponse } from 'axios';

const Config = require('Config');

export default class BankAccountService extends Service {
  constructor() {
    super(Config.bankAccountServiceUrl);
  }

  async getBankAccounts(activeAccount: string): Promise<ClientBankInfo[] | []> {
    const response: AxiosResponse = await this.axios.get(`bankAccounts/${activeAccount}`);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
