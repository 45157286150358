import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { auth0Context } from '../services/service';
//
// NOTE: This is a special version for using axios with redux query in the dashboard.
// Use axiosUtils.ts is you aren't using redux query.
//

export const getToken: any = async () => {
  const token = window.localStorage.getItem(LocalAuth.TOKEN_KEY);
  if (token) {
    const json = JSON.parse(token);
    return json.token;
  }
  const auth0Jwt = await auth0Context.getAccessTokenSilently();
  if (auth0Jwt) return auth0Jwt;
  return null;
};

export const getAuthorizationHeader = async () => `Bearer ${await getToken()}`;

const axiosServices = () => {
  const services = axios.create();
  services.interceptors.response.use(
    (response) => response,
    // NOTE: If we send back structured errors from the service
    // All error handling could happen here including launching necessary snackbars
    (error) => Promise.reject(error.response?.data || 'Service Error'),
  );
  return services;
};

type AxiosArgs = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
};

export const axiosBaseQuery =
  (axiosInstance: AxiosInstance): BaseQueryFn<AxiosArgs, unknown, unknown> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const createBaseQuery = () => {
  const axiosInstance: AxiosInstance = axiosServices();
  return axiosBaseQuery(axiosInstance);
};

export default axiosServices;
