const basicPlanFeatures: { feature: string; showBullet: boolean }[] = [
  { feature: 'convenient_mobile_and_web_access', showBullet: true },
  { feature: 'basic_load_entry', showBullet: true },
  { feature: 'IFTA_web_only', showBullet: true },
  { feature: 'unlimited_users', showBullet: true },
  { feature: 'unlimited_trucks_drivers_and_loads', showBullet: true },
  { feature: 'create_loads_from_credit', showBullet: true },
  { feature: 'manage_booked_loads_from_freight', showBullet: true },
  { feature: 'direct_invoicing_to_rts_financial', showBullet: true },
];

const premiumFeatures: { feature: string; showBullet: boolean }[] = [
  { feature: 'everything_in_basic', showBullet: false },
  { feature: 'advanced_load_entry', showBullet: true },
  { feature: 'my_fleet_with_expanded_profiles', showBullet: true },
  { feature: 'automatic_wex_fuel_import', showBullet: true },
  { feature: 'driver_pay_statements', showBullet: true },
  { feature: 'email_invoices', showBullet: true },
  { feature: 'aging_report', showBullet: true },
  { feature: 'dispatch_board', showBullet: true },
];

const enterpriseFeatures: { feature: string; showBullet: boolean }[] = [
  { feature: 'all_in_one_trucking_software', showBullet: false },
  { feature: 'everything_in_premium', showBullet: false },
  { feature: 'multiple_integration_including_ifta_quickbooks_eld_and_more', showBullet: true },
  { feature: 'accounting_dispatch_safety_and_maintenance', showBullet: true },
  { feature: 'dedicated_implementation_support_team', showBullet: true },
  { feature: 'driver_mobile_app', showBullet: true },
];

export const COMPANY_PLANS = [
  {
    name: 'Basic',
    features: basicPlanFeatures,
  },
  {
    name: 'Premium',
    features: premiumFeatures,
  },
  {
    name: 'Enterprise',
    features: enterpriseFeatures,
  },
];
