import React from 'react';
import NotificationsDrawerCompliance from './notifications-drawer-compliance';

interface NotificationsDrawerContentProps {
  tab: string;
  toggleDrawer: () => void;
}

export default function NotificationsDrawerContent({ tab, toggleDrawer }: Readonly<NotificationsDrawerContentProps>) {
  const contentRender = () => {
    switch (tab) {
      case 'compliance':
        return <NotificationsDrawerCompliance toggleDrawer={toggleDrawer} />;
      default:
        return;
    }
  };

  return <div style={{ flex: '1' }}>{contentRender()}</div>;
}
