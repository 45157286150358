import { Guid } from 'guid-typescript';

export class BusinessPartnerContact {
  id: number;
  fullName: string = '';
  phoneNumber: string = '';
  email: string = '';
  department: string = '';
  isPrimaryContact: boolean = false;
  key: string = Guid.create().toString();
  areFieldsCleared: boolean = false;
}
