import React from 'react';
import { Icon } from 'shamrock-clover-ui';
import { MenuOption, SingleSelectMenu } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import css from './styles/pill-component.scss';

export type PillComponentProps = {
  dropdownDownWidth?: number;
  selectedOption: MenuOption;
  options: MenuOption[];
  onOptionSelected: (option?: MenuOption) => void;
  rightComponent?: any;
  showDivider?: boolean;
};

const PillComponent = ({
  dropdownDownWidth,
  selectedOption,
  options,
  onOptionSelected,
  rightComponent,
  showDivider,
}: PillComponentProps) => {
  return (
    <div className={css.toolbarInput}>
      <SingleSelectMenu
        menuLocation={'below'}
        selectedOption={selectedOption}
        onOptionSelected={(selectMenuOption) => {
          selectMenuOption.optionName === '' ? onOptionSelected(selectedOption) : onOptionSelected(selectMenuOption);
        }}
        menuOptions={options}
        VisualElement={
          <div
            style={{
              height: 30,
              fontSize: '14px',
              width: dropdownDownWidth,
              minWidth: 104,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              cursor: 'pointer',
            }}
          >
            <div>{selectedOption.optionName}</div>
            <Icon icon={'dropdownDown'} className={css.dropdownIcon} />
          </div>
        }
      />
      {showDivider && <div className={css.toolbarInputDivider} />}
      {showDivider && rightComponent}
    </div>
  );
};

export default PillComponent;
