import { getUserStatusQuery } from '../interfaces/graphQL/GetUserStatusQuery';
import { UpdateUserQueryModel, updateUserQuery } from '../interfaces/graphQL/UpdateUserQueryModel';
import Service, { getAxiosInstance } from './service';
import { AxiosResponse } from 'axios';
import { User } from 'interfaces';

const Config = require('Config');

class UserService extends Service {
  private graphQlUserServiceUrl: string = Config.usersServiceUrl;

  constructor() {
    super(Config.userServiceUrl);
  }

  async getUser(email): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`user/${email}`);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  // TODO: Deprecate method: use GraphQL method instead
  async patchUser(email: string, data: any): Promise<any> {
    const response: AxiosResponse = await this.axios.patch(`user/${email}`, data);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateUser(queryVariables: UpdateUserQueryModel) {
    const dataQuery = {
      query: updateUserQuery,
      variables: queryVariables,
    };

    const axios = getAxiosInstance(this.graphQlUserServiceUrl);
    const response = await axios.post('/', dataQuery);

    if (response.status !== 200) {
      throw new Error();
    }

    return response.data.updateUser as User;
  }

  async getUserStatus(email: string): Promise<Partial<User>> {
    const dataQuery = {
      query: getUserStatusQuery,
      variables: {
        email,
      },
    };

    const axios = getAxiosInstance(this.graphQlUserServiceUrl);
    const response = await axios.post('/', dataQuery);

    if (response.status !== 200) {
      throw new Error();
    }

    return response.data.user.userStatus as Partial<User>;
  }
}

export default UserService;
