import { AxiosResponse } from 'axios';
import { ApiError } from '../types/ApiError';
import { BusinessPartner } from '../types/BusinessPartner';
import { Country } from '../types/Country';
import { Driver } from '../types/Driver';
import { EquipmentMake } from '../types/EquipmentMake';
import { EquipmentModel } from '../types/EquipmentModel';
import { FuelEntry } from '../types/FuelEntry';
import { Note } from '../../../interfaces/note';
import { TmsCompany } from '../types/TmsCompany';
import { Trailer } from '../types/Trailer';
import { TrailerDoorType } from '../types/TrailerDoorType';
import { TrailerType } from '../types/TrailerType';
import { Truck } from '../types/Truck';
import { VinDecoderResponse } from '../types/Vin';
import http from '../../../utils/axios-utils';
import { getCompanyId } from '../../../utils/authUtils';
import uuid4 from 'uuid4';

// TODO: Replace most of these with endpoints from the eventual Equipment Service
const Config = require('Config');
const { loadManagementServiceUrl } = Config;
const { equipmentServiceUrl } = Config;

//VIN Decoding
export const checkVin = async (vinInput?: string) => {
  try {
    const response = await http.post<VinDecoderResponse, unknown>(`${equipmentServiceUrl}/equipment`)({
      vin: vinInput,
    });
    return response.data;
  } catch (error) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

// General

export const getCountries = () => http.get<Country[], unknown>(`${loadManagementServiceUrl}country`)();

export const getFuelTypes = () => http.get<FuelEntry[], unknown>(`${loadManagementServiceUrl}fuellogs/fueltypes/`)();

export const getTmsCompany = async () =>
  http.get<TmsCompany, unknown>(`${loadManagementServiceUrl}company/crm/${await getCompanyId()}`)();

// Trucks

export const getTrucks = (activeOnly?: boolean) => {
  let url = `${loadManagementServiceUrl}trucks`;
  if (activeOnly) {
    url = `${loadManagementServiceUrl}trucks/?isActive=true`;
  }
  return http.get<Truck[], unknown>(url)();
};

export const getTruck = (id?: number) => http.get<Truck, unknown>(`${loadManagementServiceUrl}trucks/${id}`)();

export const canDeleteTruck = (id: number) =>
  http.get<boolean, unknown>(`${loadManagementServiceUrl}trucks/${id}/candelete`)();

export const deleteTruck = (id?: number) => http.delete<number, unknown>(`${loadManagementServiceUrl}trucks/${id}`)();

export const saveTruck = async (truck?: Truck) => {
  try {
    if (truck?.id) {
      const body = truck?.transactionKey ? truck : { ...truck, transactionKey: uuid4() };
      return await http.patch<Truck, unknown>(`${loadManagementServiceUrl}trucks`)(body);
    } else {
      return await http.post<Truck, unknown>(`${loadManagementServiceUrl}trucks`)(truck);
    }
  } catch (error) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

export const updateTruck = async (truckId: number, patchInfo: { key: string; value: any }[]): Promise<Truck> => {
  const { data } = await http.patch<Truck, unknown>(`${equipmentServiceUrl}/trucks/${truckId}`)(patchInfo);
  return data;
};

export const getTruckNotes = (id: number) =>
  http.get<Note[], unknown>(`${loadManagementServiceUrl}trucks/${id}/notes`)();

export const addTruckNote = (note: Note, truckId: number) =>
  http.post<Note[], unknown>(`${loadManagementServiceUrl}trucks/${truckId}/notes`)(note);

export const editTruckNote = (noteId: number, truckId: number, message: string) =>
  http.patch<Note[], unknown>(`${loadManagementServiceUrl}trucks/${truckId}/notes/${noteId}`)({ note: message });

export const deleteTruckNote = (noteId: number, truckId: number) =>
  http.delete<number, unknown>(`${loadManagementServiceUrl}trucks/${truckId}/notes/${noteId}`)();

// Trailers
export const getTrailerTypes = () => http.get<TrailerType[], unknown>(`${equipmentServiceUrl}/trailers/types`)();

export const getTrailers = (activeOnly?: boolean) => {
  let url = `${equipmentServiceUrl}/trailers`;
  if (activeOnly) {
    url = `${equipmentServiceUrl}/trailers/?isActive=true`;
  }
  return http.get<Trailer[], unknown>(url)();
};

export const getTrailer = (id?: number) => http.get<Trailer, unknown>(`${equipmentServiceUrl}/trailers/${id}`)();

export const canDeleteTrailer = (id: number) =>
  http.get<boolean, unknown>(`${equipmentServiceUrl}/trailers/${id}/candelete`)();

export const deleteTrailer = (id?: number) => http.delete<number, unknown>(`${equipmentServiceUrl}/trailers/${id}`)();

export const saveTrailer = async (trailer?: Trailer) => {
  try {
    if (trailer?.id) {
      const body = trailer?.transactionKey ? trailer : { ...trailer, transactionKey: uuid4() };
      return await http.put<Trailer, unknown>(`${equipmentServiceUrl}/trailers`)(body);
    } else {
      return await http.post<Trailer, unknown>(`${equipmentServiceUrl}/trailers`)(trailer);
    }
  } catch (error) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

export const updateTrailer = async (trailerId: number, patchInfo: { key: string; value: any }[]): Promise<Trailer> => {
  const { data } = await http.patch<Trailer, unknown>(`${equipmentServiceUrl}/trailers/${trailerId}`)(patchInfo);
  return data;
};

export const getTrailerNotes = (id: number) =>
  http.get<Note[], unknown>(`${equipmentServiceUrl}/trailers/${id}/notes`)();

export const addTrailerNote = (note: Note, trailerId: number) =>
  http.post<Note[], unknown>(`${equipmentServiceUrl}/trailers/${trailerId}/notes`)(note);

export const editTrailerNote = (noteId: number, trailerId: number, message: string) =>
  http.patch<Note[], unknown>(`${equipmentServiceUrl}/trailers/${trailerId}/notes/${noteId}`)({ note: message });

export const deleteTrailerNote = (noteId: number, trailerId: number) =>
  http.delete<number, unknown>(`${equipmentServiceUrl}/trailers/${trailerId}/notes/${noteId}`)();

export const getTrailerDoorTypes = () =>
  http.get<TrailerDoorType[], unknown>(`${equipmentServiceUrl}/trailers/door_types`)();

// Business Partners and Drivers

export const getBusinessPartners = () =>
  http.get<BusinessPartner[], unknown>(`${loadManagementServiceUrl}businesspartners`)();

export const getBusinessPartner = (id: number) =>
  http.get<BusinessPartner, unknown>(`${loadManagementServiceUrl}businesspartners/${id}`)();

export const saveDriver = async (driver: Driver | BusinessPartner, isNew: boolean): Promise<any> => {
  let response: AxiosResponse;
  try {
    const url = `${loadManagementServiceUrl}drivers`;
    if (isNew) {
      response = await http.post<Driver, unknown>(url)(driver);
    } else {
      response = await http.patch<Driver, unknown>(url)(driver);
    }
    if (response.status > 299) {
      throw response;
    }
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

// Equipment

export const getEquipmentMakes = (vehicleType: 'truck' | 'trailer') =>
  http.get<EquipmentMake[], unknown>(`${equipmentServiceUrl}/equipment/${vehicleType}/make`)();
export const getEquipmentModels = (vehicleType: 'truck' | 'trailer', makeId: number | undefined) =>
  http.get<EquipmentModel[], unknown>(`${equipmentServiceUrl}/equipment/${vehicleType}/make/${makeId}/model`)();
