import { Truck } from '../../interfaces/truck';
import { SET_EQUIPMENT_TRUCKS, SET_EQUIPMENT_TRAILERS } from '../../constants/equipmentConstants';
import { sortString } from '../../utils/sorting-utils';
import { Trailer } from '../../interfaces/trailer';
const initialState = { equipmentTrucks: [], equipmentTrailers: [] };

export default function equipmentReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case SET_EQUIPMENT_TRUCKS:
      return { ...newState, equipmentTrucks: sortTrucks(action.trucks) };
    case SET_EQUIPMENT_TRAILERS:
      return { ...newState, equipmentTrailers: sortTrailers(action.trailers) };
    default:
      return { ...newState };
  }

  function sortTrucks(trucks: Truck[] = []) {
    const sortedTrucks = trucks?.slice().sort((a: Truck, b: Truck) => {
      return sortString(a.truckNumber, b.truckNumber);
    });
    return sortedTrucks;
  }

  function sortTrailers(trailers: Trailer[] = []) {
    const sortedTrailers = trailers?.slice().sort((a: Trailer, b: Trailer) => {
      return sortString(a.trailerNumber, b.trailerNumber);
    });
    return sortedTrailers;
  }
}
