import { DriverSchedule } from '../interfaces/driver-schedule';

export function sortString(a, b) {
  if (a === b) {
    return 0;
  } else if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  } else {
    return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
  }
}

export function sortNumber(a, b, reverseUndefined?: boolean) {
  if (a === b) {
    return 0;
  } else if (!a) {
    return reverseUndefined ? 1 : -1;
  } else if (!b) {
    return reverseUndefined ? -1 : 1;
  } else {
    return a > b ? 1 : -1;
  }
}

export const sortDriversByMilesThenName = (a: DriverSchedule, b: DriverSchedule): number => {
  const milesA = a.driver.milesBetweenTrips;
  const milesB = b.driver.milesBetweenTrips;

  // Place milesA === 0 at the top
  if (milesA === 0 && milesB !== 0) return -1;
  if (milesB === 0 && milesA !== 0) return 1;

  // Sort positive miles in ascending order
  if (milesA !== null && milesA > 0 && milesB !== null && milesB > 0) {
    const diff = milesA - milesB;
    if (diff !== 0) return diff;
  }

  // Place null and -1 at the bottom
  const isMilesANullOrNeg = milesA === null || milesA === -1;
  const isMilesBNullOrNeg = milesB === null || milesB === -1;

  if (isMilesANullOrNeg && !isMilesBNullOrNeg) return 1;
  if (isMilesBNullOrNeg && !isMilesANullOrNeg) return -1;

  // Compare by first name
  const firstNameComparison = (a.driver.firstName ?? '').localeCompare(b.driver.firstName ?? '');
  if (firstNameComparison !== 0) return firstNameComparison;

  // Compare by last name
  return (a.driver.lastName ?? '').localeCompare(b.driver.lastName ?? '');
};
