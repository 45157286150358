import { TFunction } from 'i18next';

export enum LoadStatus {
  All = 0,
  Booked = 1,
  InTransitOnTime = 2,
  InTransitLate = 3,
  Delivered = 4,
  ReadyToBill = 5,
  Invoiced = 6,
  Paid = 7,
  Cancelled = 8,
  Tonu = 9,
}

export const getStatusColor = (status?: string, id?: number) => {
  if (status) {
    switch (status) {
      case 'Invoiced':
        return '#FF9000';
      case 'InTransitOnTime':
        return '#62C3FF';
      case 'InTransitLate':
        return '#EF3824';
      case 'Delivered':
        return '#C0DB18';
      case 'ReadyToBill':
        return '#72BF44';
      case 'Paid':
        return '#F7B500';
      case 'Cancelled':
        return '#444';
      case 'Tonu':
        return '#F04AFF';
      default:
        return '#0091EA';
    }
  }
  if (id) {
    switch (id) {
      case LoadStatus.Invoiced:
        return '#FF9000';
      case LoadStatus.InTransitOnTime:
        return '#62C3FF';
      case LoadStatus.InTransitLate:
        return '#EF3824';
      case LoadStatus.Delivered:
        return '#C0DB18';
      case LoadStatus.ReadyToBill:
        return '#72BF44';
      case LoadStatus.Paid:
        return '#F7B500';
      case LoadStatus.Cancelled:
        return '#444';
      case LoadStatus.Tonu:
        return '#F04AFF';
      default:
        return '#0091EA';
    }
  }
};

export const getStatusList = (isEnterprise: boolean = true) =>
  Object.values(LoadStatus)
    .filter((value) => isNaN(Number(value)) && filterStatusByRole(value.toString(), isEnterprise))
    .map((status) => status.toString());

export const getStatusName = (t: TFunction<'translation'>, status?: string, id?: number) => {
  if (status) {
    switch (status) {
      case 'All':
        return t('all');
      case 'InTransitOnTime':
        return t('In-Transit On Time');
      case 'InTransitLate':
        return t('In-Transit Late');
      case 'Delivered':
        return t('Delivered');
      case 'ReadyToBill':
        return t('Ready to Bill');
      case 'Invoiced':
        return t('Invoiced');
      case 'Paid':
        return t('Paid');
      case 'Cancelled':
        return t('Cancelled');
      case 'Tonu':
        return t('TONU');
      default:
        return t('Booked');
    }
  }
  if (id) {
    switch (id) {
      case LoadStatus.InTransitOnTime:
        return t('In-Transit On Time');
      case LoadStatus.InTransitLate:
        return t('In-Transit Late');
      case LoadStatus.Delivered:
        return t('Delivered');
      case LoadStatus.ReadyToBill:
        return t('Ready to Bill');
      case LoadStatus.Invoiced:
        return t('Invoiced');
      case LoadStatus.Paid:
        return t('Paid');
      case LoadStatus.Cancelled:
        return t('Cancelled');
      case LoadStatus.Tonu:
        return t('TONU');
      default:
        return t('Booked');
    }
  }
};

export const getStatusValue = (status: string): number | undefined => {
  const statusEnum = LoadStatus;
  for (const key in statusEnum) {
    if (statusEnum[key] === status) {
      return parseInt(key, 10);
    }
  }
  return undefined;
};

const filterStatusByRole = (status: string, isEnterprise: boolean) => {
  switch (status) {
    case 'All':
      return true;
    case 'Booked':
      return true;
    case 'InTransitOnTime':
      return isEnterprise;
    case 'InTransitLate':
      return isEnterprise;
    case 'Delivered':
      return isEnterprise;
    case 'ReadyToBill':
      return isEnterprise;
    case 'Invoiced':
      return true;
    case 'Paid':
      return true;
    case 'Cancelled':
      return isEnterprise;
    case 'Tonu':
      return isEnterprise;
    default:
      return false;
  }
};
