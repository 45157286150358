export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_DRIVER_SCHEDULES = 'SET_DRIVER_SCHEDULES';
export const SET_IS_GRID_LOADING = 'SET_IS_GRID_LOADING';
export const SET_FILTERED_DRIVER_SCHEDULES = 'SET_FILTERED_DRIVER_SCHEDULES';
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const SET_DRIVERS = 'SET_DRIVERS';
export const SET_DISPATCH_LOADING = 'SET_DISPATCH_LOADING';
export const SET_DISPATCH_ASSIGN_TRIPS_OPEN = 'SET_DISPATCH_ASSIGN_TRIPS_OPEN';
export const TRIPS_EXIST_ON_NEXT_WEEK = 'TRIPS_EXIST_ON_NEXT_WEEK';
export const TRIPS_EXIST_ON_PREVIOUS_WEEK = 'TRIPS_EXIST_ON_PREVIOUS_WEEK';
export const TRIPS_EXIST_ON_NEXT_PREVIOUS_WEEK = 'TRIPS_EXIST_ON_NEXT_PREVIOUS_WEEK';
export const SET_TRIP_DETAILS = 'SET_TRIP_DETAILS';
export const SET_TRIP_LIST = 'SET_TRIP_LIST';
export const SET_FILTERED_TRIP_LIST = 'SET_FILTERED_TRIP_LIST';
export const SET_SEARCH_CRITERIA_TRIP_LIST = 'SET_SEARCH_CRITERIA_TRIP_LIST';
export const SET_TRIP_ID = 'SET_TRIP_ID';
export const UPDATE_STOP = 'UPDATE_STOP';
export const SET_TRIP_DETAIL_SIDE_PANEL_OPEN = 'SET_TRIP_DETAIL_SIDE_PANEL_OPEN';
export const SET_TRIP_IS_LATE = 'SET_TRIP_IS_LATE';
export const SET_ASSIGNMENT_PANEL_LOADING = 'SET_ASSIGNMENT_PANEL_LOADING';
export const SET_ASSIGNMENT_PANEL_DRIVERS = 'SET_ASSIGNMENT_PANEL_DRIVERS';
export const UPDATE_TRIP_STATUS = 'UPDATE_TRIP_STATUS';
export const SET_UNASSIGNED_TRIPS = 'SET_UNASSIGNED_TRIPS';
export const UPDATE_TRUCK = 'UPDATE_TRUCK';
export const UPDATE_TRAILER = 'UPDATE_TRAILER';
export const ASSIGN_TRIP_TO_DRIVER = 'ASSIGN_TRIP_TO_DRIVER';
export const UNASSIGN_TRIP_FROM_DRIVER = 'UNASSIGN_TRIP_FROM_DRIVER';
