import Service from './service';
import { faker } from '@faker-js/faker';
import { DispatchDriver } from '../interfaces/dispatch-driver';
import { DispatchGroup } from '../interfaces/dispatch-group';
import { DriverSchedule } from '../interfaces/driver-schedule';
import { DriverTimeOff } from '../interfaces/driver-time-off';
import { DispatchTrip } from '../interfaces/dispatch-trip';
import { replaceInArray } from '../utils/array-utils';
import { DispatchTruck } from '../interfaces/dispatch-truck';
import { DispatchTrailer } from '../interfaces/dispatch-trailer';
import { TripStatus } from '../interfaces/trip-status';

const Config = require('Config');

export default class MockDispatchService extends Service {
  private dispatchGroups: {
    id: number;
    name: string;
    drivers: DispatchDriver[];
    driversSchedules: DriverSchedule[];
  }[];

  constructor(numberOfDriversPerGroup: number = 20, numberOfTripsPerDay: number = 2) {
    super(Config.dispatchServiceUrl);
    this.dispatchGroups = [
      {
        id: 1,
        name: 'Long Haul',
        drivers: this.generateDrivers(numberOfDriversPerGroup, 1),
        driversSchedules: new Array<DriverSchedule>(),
      },
      {
        id: 2,
        name: 'Regional',
        drivers: this.generateDrivers(numberOfDriversPerGroup, 2),
        driversSchedules: new Array<DriverSchedule>(),
      },
      {
        id: 3,
        name: 'Local',
        drivers: this.generateDrivers(numberOfDriversPerGroup, 3),
        driversSchedules: new Array<DriverSchedule>(),
      },
    ];
    this.assignDriverSchedules(1);
    this.assignDriverSchedules(2);
    this.assignDriverSchedules(3);
    this.assignTrips(numberOfTripsPerDay);
  }

  private getRandomNumberInRange(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private generateDrivers = (numberOfDrivers: number, groupId: number): DispatchDriver[] => {
    const drivers = new Array<DispatchDriver>();
    for (let i = 0; i < numberOfDrivers; i++) {
      drivers.push({
        id: `${groupId}${i + 1}`,
        firstName: faker.person.firstName(),
        lastName: faker.person.lastName(),
        profileImageKey: undefined,
        phoneNumber: faker.phone.number(),
        email: faker.internet.email(),
        milesLast30Days: this.getRandomNumberInRange(1000, 5000),
        earningsLast30Days: this.getRandomNumberInRange(2000, 8000),
      });
    }
    return drivers;
  };

  private assignDriverSchedules = (dispatchGroupId: number) => {
    const foundDispatchGroup = this.dispatchGroups.find((group) => group.id == dispatchGroupId);
    if (foundDispatchGroup) {
      const driversToAssign = foundDispatchGroup.drivers;
      for (let driver of driversToAssign) {
        const assignedDriver = driver;
        const truck = new DispatchTruck();
        const trailer = new DispatchTrailer();
        trailer.id = this.getRandomNumberInRange(1, 999);
        trailer.trailerNumber = faker.vehicle.vin();
        truck.id = this.getRandomNumberInRange(1, 999);
        truck.truckNumber = faker.vehicle.vin();
        const timeOffSchedule = this.getRandomNumberInRange(1, 3);
        const assignedDriverTimeOff = this.getTimeOff(timeOffSchedule);
        const assignedDriverSchedule = new DriverSchedule();
        assignedDriverSchedule.driver = assignedDriver;
        assignedDriverSchedule.truck = truck;
        assignedDriverSchedule.trailer = trailer;
        assignedDriverSchedule.timeOffs = assignedDriverTimeOff;
        assignedDriverSchedule.trips = [];
        this.dispatchGroups.find((group) => group.id == dispatchGroupId).driversSchedules.push(assignedDriverSchedule);
      }
    }
  };

  private addDaysToDate(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  private assignTrips = (numberOfTripsPerDay: number) => {
    const groups = [...this.dispatchGroups];
    groups.forEach((group) => {
      const [startDate, endDate] = this.getTimeFrame();
      const originalGroup = this.dispatchGroups.find((g) => g.id === group.id);
      let currentDate = startDate;
      while (currentDate <= endDate) {
        for (let i = 0; i < numberOfTripsPerDay; i++) {
          let durationOfTrip: number = 1;
          if (group.id === 1) {
            durationOfTrip = this.getRandomNumberInRange(3, 4);
          } else if (group.id === 2) {
            durationOfTrip = this.getRandomNumberInRange(1, 3);
          } else if (group.id === 3) {
            durationOfTrip = this.getRandomNumberInRange(0, 1);
          }
          const tripEndDate = this.addDaysToDate(currentDate, durationOfTrip);
          const newlyCreatedTrip = new DispatchTrip();
          newlyCreatedTrip.scheduledStartDateTime = currentDate;
          newlyCreatedTrip.scheduledEndDateTime = tripEndDate;

          newlyCreatedTrip.actualStartDateTime = new Date(currentDate);
          newlyCreatedTrip.actualEndDateTime = new Date(tripEndDate);

          newlyCreatedTrip.actualStartDateTime.setHours(currentDate.getHours() + this.getRandomNumberInRange(1, 12));
          newlyCreatedTrip.actualEndDateTime.setHours(tripEndDate.getHours() + this.getRandomNumberInRange(1, 12));

          if (newlyCreatedTrip.scheduledStartDateTime.getTime() === newlyCreatedTrip.scheduledEndDateTime.getTime()) {
            const numberOfHoursToAdd = this.getRandomNumberInRange(1, 23);
            newlyCreatedTrip.scheduledEndDateTime.setHours(tripEndDate.getHours() + numberOfHoursToAdd);
          }
          newlyCreatedTrip.startCity = faker.location.city();
          newlyCreatedTrip.startState = faker.location.state();
          newlyCreatedTrip.endCity = faker.location.city();
          newlyCreatedTrip.endState = faker.location.state({
            abbreviated: true,
          });
          newlyCreatedTrip.status = this.assignTripStatus(currentDate, tripEndDate);
          newlyCreatedTrip.pickupCount = this.getRandomNumberInRange(1, 5);
          newlyCreatedTrip.deliveryCount = this.getRandomNumberInRange(1, 5);
          newlyCreatedTrip.loadNumbers = new Array<string>();
          const generatedLoadNumber = faker.vehicle.vrm();
          newlyCreatedTrip.loadNumbers.push(generatedLoadNumber);

          const newDriverSchedule = this.assignDriverToTrip(originalGroup.driversSchedules, newlyCreatedTrip);
          if (newDriverSchedule) {
            const index = originalGroup.driversSchedules.findIndex(
              (ds) => ds.driver.id === newDriverSchedule.driver.id,
            );
            if (index !== -1)
              originalGroup.driversSchedules = replaceInArray(
                [...originalGroup.driversSchedules],
                newDriverSchedule,
                index,
              );
          }
        }

        currentDate = this.addDaysToDate(currentDate, 1);
      }
    });
  };

  private assignTripStatus = (startDate: Date, endDate: Date) => {
    const currentDate = new Date();
    if (startDate < currentDate && endDate < currentDate) {
      return TripStatus.Delivered;
    } else if (startDate < currentDate && endDate > currentDate) {
      return TripStatus.InTransit;
    } else if (startDate > currentDate && endDate > currentDate) {
      return TripStatus.Available;
    } else {
      return TripStatus.Planned;
    }
  };

  private assignDriverToTrip = (driverSchedules: DriverSchedule[], trip: DispatchTrip): DriverSchedule => {
    let driverScheduleAssignment: DriverSchedule;
    let numberOfAttempts = 0;
    for (let i = 0; i < driverSchedules.length; i++) {
      numberOfAttempts = numberOfAttempts++;
      const randomNumber = this.getRandomNumberInRange(0, driverSchedules.length - 1);
      const driverSchedule = driverSchedules[randomNumber];
      // Verify driver's time offs
      if (
        driverSchedule.timeOffs.some(
          (timeOff) => trip.scheduledStartDateTime <= timeOff.endDate && trip.scheduledEndDateTime >= timeOff.startDate,
        )
      ) {
        // do nothing
      }

      // Verify driver's assigned trips
      else if (
        driverSchedule.trips.some(
          (assignedTrip) =>
            (assignedTrip.scheduledStartDateTime.getTime() >= trip.scheduledStartDateTime.getTime() &&
              assignedTrip.scheduledStartDateTime.getTime() <= trip.scheduledEndDateTime.getTime()) ||
            (assignedTrip.scheduledEndDateTime.getTime() >= trip.scheduledStartDateTime.getTime() &&
              assignedTrip.scheduledEndDateTime.getTime() <= trip.scheduledEndDateTime.getTime()),
        )
      ) {
        // do nothing
      } else {
        driverScheduleAssignment = driverSchedule;
      }
      if (driverScheduleAssignment) driverScheduleAssignment.trips.push(trip);
      return driverScheduleAssignment;
    }
  };

  private getTimeFrame(): Date[] {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - 14); // 2 weeks ago
    startDate.setHours(this.getRandomNumberInRange(0, 23));
    const endDate = new Date(today);
    endDate.setDate(endDate.getDate() + 21); // 3 weeks ahead
    endDate.setHours(this.getRandomNumberInRange(0, 23));
    return [startDate, endDate];
  }

  private getTimeOff(timeOffScheduleType: number): Array<DriverTimeOff> {
    let driverTimeOff = new Array<DriverTimeOff>();
    const [startDate, endDate] = this.getTimeFrame();

    const isWeekend = (day: Date): boolean => {
      return day.getDay() === 0 || day.getDay() === 6;
    };

    const isTuesdayOrThursday = (day: Date): boolean => {
      return day.getDay() === 2 || day.getDay() === 4;
    };

    const isWednesday = (day: Date): boolean => {
      return day.getDay() === 3;
    };

    let idCounter = 1;
    let currentDate = new Date(startDate);
    switch (timeOffScheduleType) {
      case 1:
        while (currentDate <= endDate) {
          if (isWeekend(currentDate)) {
            const weekendStartDate = new Date(new Date(new Date(currentDate.setHours(0)).setMinutes(0)).setSeconds(0));
            const weekendEndDate = new Date(new Date(new Date(currentDate.setHours(47)).setMinutes(59)).setSeconds(59));
            driverTimeOff.push({
              id: idCounter++,
              startDate: weekendStartDate,
              endDate: weekendEndDate,
            });
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        break;
      case 2:
        while (currentDate <= endDate) {
          if (isTuesdayOrThursday(currentDate)) {
            const tuesOrThursStartDate = new Date(
              new Date(new Date(currentDate.setHours(0)).setMinutes(0)).setSeconds(0),
            );
            const tuesOrThursEndDate = new Date(
              new Date(new Date(currentDate.setHours(23)).setMinutes(59)).setSeconds(59),
            );
            driverTimeOff.push({
              id: idCounter++,
              startDate: tuesOrThursStartDate,
              endDate: tuesOrThursEndDate,
            });
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        break;
      case 3:
        while (currentDate <= endDate) {
          if (isWednesday(currentDate)) {
            const wednesdayNoon = new Date(new Date(new Date(currentDate.setHours(12)).setMinutes(0)).setSeconds(0));
            const thursdayNoon = new Date(new Date(new Date(currentDate.setHours(36)).setMinutes(0)).setSeconds(0));
            driverTimeOff.push({
              id: idCounter++,
              startDate: wednesdayNoon,
              endDate: thursdayNoon,
            });
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        break;
    }
    return driverTimeOff;
  }

  async getDispatchGroups(): Promise<DispatchGroup[]> {
    return this.dispatchGroups;
  }

  async getDrivers(dispatchGroupId: number): Promise<DispatchDriver[]> {
    const foundDispatchGroup = this.dispatchGroups.find((group) => group.id == dispatchGroupId);
    if (foundDispatchGroup) {
      return foundDispatchGroup.drivers;
    }
    return null;
  }

  async getDriversSchedules(
    startDate: Date,
    endDate: Date,
    driverIds: number[] | string[],
    dispatchGroupId: number,
  ): Promise<Array<DriverSchedule>> {
    const foundDispatchGroup = this.dispatchGroups.find((group) => group.id == dispatchGroupId);
    if (foundDispatchGroup) {
      let driversSchedulesToReturn = new Array<DriverSchedule>();
      const initialDate = new Date(startDate);
      initialDate.setHours(initialDate.getHours() - 24);

      const finalDate = new Date(endDate);
      finalDate.setHours(finalDate.getHours() + 24);
      for (let driverSchedule of foundDispatchGroup.driversSchedules) {
        // @ts-ignore
        if (driverIds.includes(driverSchedule.driver.id)) {
          driverSchedule.timeOffs = driverSchedule.timeOffs.filter(
            (timeOff) =>
              timeOff.startDate.getTime() >= startDate.getTime() && timeOff.endDate.getTime() <= endDate.getTime(),
          );
          driverSchedule.trips = driverSchedule.trips.filter(
            (trip) =>
              trip.scheduledStartDateTime.getTime() >= initialDate.getTime() &&
              trip.scheduledEndDateTime.getTime() <= finalDate.getTime(),
          );

          driversSchedulesToReturn.push(driverSchedule);
        }
      }
      return driversSchedulesToReturn;
    }
    return null;
  }
}
