export const SET_COMPANY = 'SET_COMPANY';
export const SET_TMS_COMPANY = 'SET_TMS_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_SHOW_SNACK_BAR = 'SET_SHOW_SNACK_BAR';
export const SET_SNACK_BAR_CONFIG = 'SET_SNACK_BAR_CONFIG';
export const SET_SHOW_WAIT = 'SET_SHOW_WAIT';
export const SHOW_SIMPLE_SNACK_BAR = 'SHOW_SIMPLE_SNACK_BAR';
export const SET_STATE_LIST = 'SET_STATE_LIST';
export const SET_FINANCIAL_USER = 'SET_FINANCIAL_USER';
export const SET_FEATURES = 'SET_FEATURES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_MAINTENANCE_ALERT = 'SET_MAINTENANCE_ALERT';
export const SET_JOYRIDE_INTRO = 'SET_JOYRIDE_INTRO';
export const SET_CLIENT_NUMBER = 'SET_CLIENT_NUMBER';
export const SET_SEEN_JOYRIDE = 'SET_SEEN_JOYRIDE';
export const SET_SHELL_NAVIGATION_PATH = 'SET_SHELL_NAVIGATION_PATH';
