import React from 'react';
import css from './styles/notifications-drawer.scss';
import { useTranslation } from 'react-i18next';
import NavLinkBar from '../../components/core/nav-link-bar';

interface NotificationsDrawerHeaderProps {
  tab: string;
  handleTabClick: (tab: string) => void;
}
export default function NotificationsDrawerHeader({ tab, handleTabClick }: Readonly<NotificationsDrawerHeaderProps>) {
  const { t } = useTranslation();

  const tabs = [{ title: t('compliance'), index: 0, link: '#' }].filter(Boolean);

  const keyToIndex = {
    compliance: 0,
  };

  const onClickTab = (index: number) => {
    const tab = Object.keys(keyToIndex).find((key) => keyToIndex[key] === index);
    handleTabClick(tab);
  };

  return (
    <div className={css.drawerTabsContainer}>
      {keyToIndex[tab] !== null && <NavLinkBar currentIndex={keyToIndex[tab]} items={tabs} handleClick={onClickTab} />}
    </div>
  );
}
