import { useAuth0 } from '@auth0/auth0-react';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';

const Config = require('Config');

export const redirectToLogout = (destination: string) => {
  const redirect = encodeURIComponent(destination);
  window.location.href = `${Config.rtsproUrl}/logout?finaldestination=${redirect}`;
};

export const redirectToLogin = (destination: string) => {
  const origin = encodeURIComponent(destination);
  window.location.href = `${Config.rtsproUrl}/auth?finaldestination=${origin}`;
};

export const useSessionEmail = () => {
  const { user } = useAuth0();
  return LocalAuth.getEmail() ?? user.email;
};

export const getCompanyId: any = async () => {
  const company = window.localStorage.getItem(LocalAuth.COMPANY_KEY);
  if (company) {
    const json = JSON.parse(company);
    return json.company_id;
  }
  return null;
};

export const getCompanyMongoId: any = async () => {
  const company = window.localStorage.getItem(LocalAuth.COMPANY_KEY);
  if (company) {
    const json = JSON.parse(company);
    return json._id;
  }
  return null;
};
