import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import store from '../redux/configureStore';
import { getMaintenanceAlert } from '../redux/actions/appActions';
import { redirectToLogout } from '../utils/authUtils';
import LogService from './log.service';
import { Log } from '../interfaces/log';
import gk2 from '@shamrock-core/gk2';
import { Auth0ContextInterface } from '@auth0/auth0-react';

const Config = require('Config');

interface Auth0Context {
  getAccessTokenSilently?: Auth0ContextInterface['getAccessTokenSilently'];
  isAuthenticated?: boolean;
  logout?: Auth0ContextInterface['logout'];
  user?: Auth0ContextInterface['user'];
}

export const auth0Context: Auth0Context = {};

export const addAuth0Context = (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  isAuthenticated: boolean,
  logout: Auth0ContextInterface['logout'],
  user: Auth0ContextInterface['user'],
) => {
  auth0Context.getAccessTokenSilently = getAccessTokenSilently;
  auth0Context.isAuthenticated = isAuthenticated;
  auth0Context.logout = logout;
  auth0Context.user = user;
};

export const getAxiosInstance = (url: string, disableLogging: boolean = false) => {
  const axiosInstance = Axios.create({ baseURL: url });
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => requestInterceptor(config),
    (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => responseErrorInterceptor(error, disableLogging),
  );

  return axiosInstance;
};

const requestInterceptor = async (config: AxiosRequestConfig) => {
  let token = LocalAuth.getTokenValue();
  const isPureTrial = token === 'trialToken';
  const deviceId = localStorage.getItem('device_id');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else if (auth0Context.isAuthenticated) {
    const token = await auth0Context.getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
  }

  config = gk2.webInterceptor(config, isPureTrial, deviceId) as AxiosRequestConfig;
  return config;
};

const responseErrorInterceptor = async (error, disableLogging: boolean) => {
  if (!disableLogging) {
    const log: Log = {
      user: LocalAuth.getEmail(),
      error: JSON.stringify(error),
      additionalDetails: '',
    };
    new LogService().log(log);
  }
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    LocalAuth.clearStorage();
    if (auth0Context.isAuthenticated) {
      await auth0Context.logout({ logoutParams: { returnTo: window.location.origin } });
    }
    redirectToLogout(Config.loadManagementWebUrl);
  }
  if (error?.response?.status === 500 || (!error.status && error.message === 'Network Error')) {
    await store.dispatch(getMaintenanceAlert() as any);
  }

  return Promise.reject(error);
};

export default class Service {
  axios: AxiosInstance;
  private disableLogging: boolean;

  constructor(serviceUrl: string, disableLogging: boolean = false) {
    this.createAxios(serviceUrl);
    this.disableLogging = disableLogging;
  }

  createAxios(url: string) {
    this.axios = Axios.create({
      baseURL: url,
    });

    this.axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => requestInterceptor(config),
      (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
    );

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => responseErrorInterceptor(error, this.disableLogging),
    );
  }
}
