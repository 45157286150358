import React, { useEffect } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { JoyrideContext, useJoyrideContext } from './context';
import UserService from '../../services/user.service';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { JoyrideImplementationParams } from './joyride-implementations/joyrideParams';
import { JoyrideAgingReport } from './joyride-implementations/aging-report/joyride-aging-report';
import { IJoyride } from './joyride-implementations/IJoyride';
import { JoyrideTms } from './joyride-implementations/tms/joyride-tms';
import { JoyrideFuelEntry } from './joyride-implementations/fuel-entry/joyride-fuel-entry';
import { tmsJoyrideSteps } from './joyride-implementations/tms/joyride-tms-steps';
import { agingJoyrideSteps } from './joyride-implementations/aging-report/joyride-aging-report-steps';
import { useTranslation } from 'react-i18next';
import { fuelEntryJoyrideSteps } from './joyride-implementations/fuel-entry/joyride-fuel-entry-steps';
import { invoicingJoyrideSteps } from './joyride-implementations/invoicing/joyride-invoicing-steps';
import { JoyrideInvoicing } from './joyride-implementations/invoicing/joyride-invoicing';
import { NavigateFunction } from 'react-router-dom';

const buttonReset = {
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  outline: 'none',
  lineHeight: 1,
  padding: 8,
  fontFamily: "'proxima-nova', 'Roboto', Sans-serif",
  fontSize: 14,
  fontStyle: 'normal',
};

const styles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    primaryColor: '#f04',
    textColor: '#333',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    beaconSize: 36,
    zIndex: 9999,
  },
  tooltip: {
    color: '#000',
    fontFamily: "'proxima-nova', 'Roboto', Sans-serif",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    width: '320px',
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  spotlight: {
    fill: '#F9F8F8',
    filter: 'blur(7.5px)',
  },
  buttonClose: {
    display: 'none',
  },
  buttonNext: {
    ...buttonReset,
    backgroundColor: '#0091EA',
    borderRadius: '5px',
    color: '#fff',
  },
  buttonSkip: {
    ...buttonReset,
    color: '#000',
  },
  buttonBack: {
    ...buttonReset,
    color: '#0091EA',
    marginLeft: 'auto',
    marginRight: 5,
  },
};

interface Props {
  navigate: NavigateFunction;
}

export default function MultiRouteWrapper({ navigate }: Props) {
  const {
    setState,
    state: { run, stepIndex, steps, joyrideId },
  } = useJoyrideContext();
  const user = useSelector((state: any) => state.app.user);
  const isFinancial = !!user?.permissions.find(
    (permission) => permission.name === 'invoicemanager.User' && permission.status.toLowerCase() === 'active',
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (joyrideId === 'tms') {
      setState({ steps: tmsJoyrideSteps(t) });
    } else if (joyrideId === 'agingReport') {
      setState({ steps: agingJoyrideSteps(isFinancial, t) });
    } else if (joyrideId === 'fuelEntry') {
      setState({ steps: fuelEntryJoyrideSteps(t) });
    } else if (joyrideId === 'tmsInvoicing') {
      setState({ steps: invoicingJoyrideSteps(t) });
    }
  }, [joyrideId]);

  const getJoyrideProperties = () => {
    if (joyrideId === 'tms') {
      return { lastLabel: t('get_started'), skipLabel: t('skip') };
    } else if (joyrideId === 'agingReport' || joyrideId === 'fuelEntry') {
      return { lastLabel: t('got_it'), skipLabel: t('close') };
    } else if (joyrideId === 'tmsInvoicing') {
      return {
        lastLabel: t('got_it'),
        skipLabel: t('skip'),
        disableScrolling: true,
      };
    }
  };

  const joyrideProperties = getJoyrideProperties();

  const callBack = (data: CallBackProps) => {
    const params: JoyrideImplementationParams = {
      user,
      navigate,
      dispatch,
      userSrv: new UserService(),
    };
    const context: JoyrideContext = {
      setState,
    };

    let joyride: IJoyride;
    if (joyrideId === 'agingReport') joyride = new JoyrideAgingReport(context, params);
    else if (joyrideId === 'fuelEntry') joyride = new JoyrideFuelEntry(context, params);
    else if (joyrideId === 'tmsInvoicing') joyride = new JoyrideInvoicing(context, params);
    else joyride = new JoyrideTms(context, params);

    joyride.handleCallback(data);
  };

  return (
    <Joyride
      callback={callBack}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      showProgress={true}
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      disableScrolling={joyrideProperties?.disableScrolling}
      //@ts-ignore Joyride style types aren't up to date
      styles={styles}
      locale={{
        last: joyrideProperties?.lastLabel,
        skip: joyrideProperties?.skipLabel,
        back: t('back'),
        next: t('next'),
      }}
    />
  );
}
