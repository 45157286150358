import { Provider as ReduxProvider } from 'react-redux';
import { pdfjs } from 'react-pdf';
import ErrorBoundary from '../../components/core/error-boundary';
import React from 'react';
import Login from '../../components/login/login';
import * as process from 'process';
import * as firebaseUtils from '../../components/core/firebase-analytics';
import Analytics from '../../components/core/analytics';
import Router from './federated-router';
import store from '../../redux/configureStore';
import css from './federated-app.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { I18nextProvider } from 'react-i18next';
import { addLoadMangagementLanguageResources } from '../../i18n';
import { setMomentLocale } from '../../utils/timeUtils';
import { i18n } from 'i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { addAuth0Context } from '../../services/service';
import { CloverThemeProvider } from 'shamrock-clover-ui';
import { DriftThemeProvider } from 'drift-ui';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Config = require('Config');
window['firebaseModuleName'] = 'Load Management';
window['process'] = process;
require('path');

Analytics.initialize();

interface Props {
  i18n: i18n;
  clientNumber: unknown;
  features: string[];
  factoringClientSelector?: JSX.Element;
  header?: JSX.Element;
}

const TMSApp = ({ clientNumber, i18n: i18nFromShell, features, factoringClientSelector, header }: Props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [auth0Ready, setAuth0Ready] = React.useState(false);
  const [isLanguageFilesLoaded, setIsLanguageFilesLoaded] = React.useState(false);
  const { getAccessTokenSilently, isAuthenticated, logout, user, isLoading } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated && user) {
      addAuth0Context(getAccessTokenSilently, isAuthenticated, logout, user);
      setAuth0Ready(true);
    }
  }, [isAuthenticated, user, isLoading]);

  React.useEffect(() => {
    if (!isLanguageFilesLoaded) {
      addLoadMangagementLanguageResources(i18nFromShell);
    }

    const init = async () => {
      await firebaseUtils.init(Config.firebaseConfig).catch((error) => {
        console.error(error.message);
      });
      setIsLanguageFilesLoaded(true);
      setIsLoaded(true);
      setMomentLocale(i18nFromShell.language);
    };

    init();
  }, [i18nFromShell, isLanguageFilesLoaded]);

  return (
    <CloverThemeProvider>
      <DriftThemeProvider>
        <ReduxProvider store={store}>
          <ErrorBoundary>
            <I18nextProvider i18n={i18nFromShell}>
              {(auth0Ready || !isAuthenticated) && (
                <Login features={features} isAuthenticated={isAuthenticated} user={user} />
              )}
              {isLoaded ? (
                <div className={css.page}>
                  <Router
                    features={features}
                    clientNumber={clientNumber}
                    factoringClientSelector={factoringClientSelector}
                    header={header}
                  />
                </div>
              ) : (
                <CircularProgress className={css.loadingSpinner} variant={'indeterminate'} size={60} />
              )}
            </I18nextProvider>
          </ErrorBoundary>
        </ReduxProvider>
      </DriftThemeProvider>
    </CloverThemeProvider>
  );
};

export default TMSApp;
