import React, { FC } from 'react';
import css from './styles/empty-state.scss';
import { useTranslation } from 'react-i18next';

type EmptyStateProps = {
  icon: any;
  titleText: string;
  haveCallback?: boolean;
  descriptionText: string;
  createCallback?: () => void;
};

export const EmptyState: FC<EmptyStateProps> = ({
  icon,
  titleText,
  descriptionText,
  createCallback,
  haveCallback = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className={css.emptyStateContainer}>
      <img src={icon} alt={titleText} />
      <label>{titleText}</label>
      <p>
        {descriptionText}
        {haveCallback && (
          <button className={css.button} onClick={createCallback}>
            &nbsp;{t('HERE')}
          </button>
        )}
        {'.'}
      </p>
    </div>
  );
};
