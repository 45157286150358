import { ApplicationTiers } from '../enums/application-tier';

const hasPermission = (permissions: any[], requestedPermission: string): boolean => {
  return !!permissions.find((p: any) => p.name === requestedPermission && p.status === 'active');
};

//Determine User Tier on login
export const determineUserTier = (permissions: any[]): ApplicationTiers => {
  if (isEnterpriseUser(permissions)) {
    return ApplicationTiers.Enterprise;
  } else if (isPremiumUser(permissions)) {
    return ApplicationTiers.Premium;
  } else if (isBasicUser(permissions)) {
    return ApplicationTiers.Basic;
  } else {
    return ApplicationTiers.Basic;
  }
};

//Premium Features to replace hasProTransLite
export const isAllowedPremiumFeature = (permissions: any[]): boolean => {
  return isEnterpriseUser(permissions) || isPremiumUser(permissions);
};

//Enterprise User
export const isEnterpriseUser = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.user.enterprise');
};

//Premium User
export const isPremiumUser = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.user.premium');
};

//Basic User
export const isBasicUser = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.user.basic');
};

//Partners

export const userCanEditPartnerDriver = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.partners.drivers.edit');
};

export const userCanEditPartnerOwner = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.partners.owners.edit');
};

export const userCanEditPartnerAdvanced = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.partners.advancedtypes.edit');
};

// Trucks

export const userCanReadTruck = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.equipment.truck.read');
};

export const userCanEditTruck = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.equipment.truck.edit');
};

// Trailers

export const userCanReadTrailer = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.equipment.trailers.read');
};

export const userCanEditTrailer = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.equipment.trailers.edit');
};

// Notifications

export const userCanReadComplianceNotifications = (permissions: any[]): boolean => {
  return hasPermission(permissions, 'tms.warnings.compliance.read');
};
