import React, { FC } from 'react';
import styles from './styles/self-enroll-upgrade.scss';
import { Button, CheckBox } from 'shamrock-clover-ui';
import ChevronLeftBlue from '../../assets/chevron-left-blue.svg';
import { useTranslation } from 'react-i18next';
import { TextField } from 'drift-ui';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configureStore';
import { formatPhone } from '../../utils/string-utils';
import { ViewState } from './request-upgrade';
import FirebaseAnalytics from '../../components/core/firebase-analytics';
import cx from 'classnames';

type SelfEnrollUpgradeProps = {
  planName: string;
  setViewState: React.Dispatch<React.SetStateAction<ViewState>>;
};

export const SelfEnrollUpgrade: FC<SelfEnrollUpgradeProps> = ({ planName, setViewState }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.app.user);
  const [upgradeForm, setUpgradeForm] = React.useState({
    firstname: user?.firstname ?? '',
    lastname: user?.lastname ?? '',
    email: user?.email ?? '',
    phone: formatPhone(user?.phone) ?? '',
    plan: planName,
    company: user?.company ?? '',
  });

  const [errorText, setErrorText] = React.useState({
    firstname: '',
    lastname: '',
    phone: '',
    purchaseAcceptance: '',
    costAcceptance: '',
  });
  const [purchaseAcceptance, setPurchaseAcceptance] = React.useState(false);
  const [costAcceptance, setCostAcceptance] = React.useState(false);

  const formFieldChange = (field: string, value: string) => {
    let updateValue = '';
    if (field === 'phone') {
      updateValue = formatPhone(value);
    } else {
      updateValue = value;
    }
    setUpgradeForm((prev) => ({ ...prev, [field]: updateValue }));
  };

  const formValidation = () => {
    const validArr = Object.keys(upgradeForm).filter((key) => {
      if (!upgradeForm[key]) {
        setErrorText((prev) => ({ ...prev, [key]: t(`${key}_required`) }));
        return false;
      } else if (key === 'phone') {
        const cleanedPhone = upgradeForm[key].replaceAll(/\D/g, '');
        if (cleanedPhone.length < 10) {
          setErrorText((prev) => ({ ...prev, phone: t('phone_invalid') }));
          return false;
        } else {
          setErrorText((prev) => ({ ...prev, phone: '' }));
          return true;
        }
      } else {
        setErrorText((prev) => ({ ...prev, [key]: '' }));
        return true;
      }
    });
    return validArr.length === Object.keys(upgradeForm).length;
  };

  const checkBoxValidation = () => {
    if (purchaseAcceptance) {
      setErrorText((prev) => ({ ...prev, purchaseAcceptance: '' }));
    } else {
      setErrorText((prev) => ({ ...prev, purchaseAcceptance: 'error' }));
    }
    if (costAcceptance) {
      setErrorText((prev) => ({ ...prev, costAcceptance: '' }));
    } else {
      setErrorText((prev) => ({ ...prev, costAcceptance: 'error' }));
    }
    return costAcceptance && purchaseAcceptance;
  };

  const handleSubmit = () => {
    const formValid = formValidation();
    const checkboxValid = checkBoxValidation();
    if (!checkboxValid || !formValid) {
      return;
    } else {
      // Submit the form
      FirebaseAnalytics.sendEvent(
        FirebaseAnalytics.EVENTS.CLICK,
        FirebaseAnalytics.MODULES.LOADS,
        FirebaseAnalytics.PAGES.MARKETING_PLANS,
        { label: FirebaseAnalytics.LABELS.SUBMIT_UPGRADE_NOW, data: planName },
      );
      console.log('Form submitted successfully', upgradeForm);
    }
  };

  return (
    <div className={styles.selfEnrollContainer}>
      <Button buttonStyle="text" width="60px" height="20px" onClick={() => setViewState(ViewState.DEFAULT)}>
        <div className={styles.backButton}>
          <img className={styles.backArrow} src={ChevronLeftBlue} />
          Back
        </div>
      </Button>
      <div className={styles.textContainer}>
        <p className={styles.titleText}>{t('upgrade_your_pt_plan')}</p>
        <p className={styles.descriptionText}>{t('use_your_factoring_funds')}</p>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.columnContainer}>
          <TextField
            label="First Name"
            value={upgradeForm.firstname}
            onChange={(value) => formFieldChange('firstname', value.currentTarget.value)}
            onBlur={formValidation}
            className={errorText.lastname && styles.errorPadding}
            helperText={errorText.firstname}
            error={!!errorText.firstname}
          />
          <TextField
            className={errorText.phone && styles.errorPadding}
            label="Email"
            value={upgradeForm.email}
            disabled
          />
          <TextField label="Company Name" value={upgradeForm.company} disabled />
        </div>
        <div className={styles.columnContainer}>
          <TextField
            label="Last Name"
            value={upgradeForm.lastname}
            onChange={(value) => formFieldChange('lastname', value.currentTarget.value)}
            onBlur={formValidation}
            className={errorText.firstname && styles.errorPadding}
            helperText={errorText.lastname}
            error={!!errorText.lastname}
          />
          <TextField
            label="Phone"
            value={upgradeForm.phone}
            onChange={(value) => formFieldChange('phone', value.currentTarget.value)}
            onBlur={formValidation}
            helperText={errorText.phone}
            error={!!errorText.phone}
          />
          <TextField label="Plan" value={upgradeForm.plan} disabled />
        </div>
      </div>
      <div className={styles.checkboxContainer}>
        <div className={styles.checkbox}>
          <CheckBox checked={purchaseAcceptance} onClick={() => setPurchaseAcceptance((prev) => !prev)} />
          <p className={cx(styles.checkboxText, errorText.purchaseAcceptance && styles.redText)}>
            {t('purchase_acceptance_text')}
          </p>
        </div>
        <div className={styles.checkbox}>
          <CheckBox checked={costAcceptance} onClick={() => setCostAcceptance((prev) => !prev)} />
          <p className={cx(styles.checkboxText, errorText.costAcceptance && styles.redText)}>
            {t('cost_acceptance_text', { cost: 125 })}
          </p>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={handleSubmit}>{t('upgrade_to_pt_premium')}</Button>
      </div>
      <p className={styles.disclaimerText}>{t('disclaimer_text_1')}</p>
      <p className={styles.disclaimerText}>{t('disclaimer_text_2')}</p>
    </div>
  );
};
