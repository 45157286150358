import { DispatchTruck } from './dispatch-truck';
import { TripStatus } from './trip-status';
import { DispatchTrailer } from './dispatch-trailer';
import { DispatchStop } from './dispatch-stop';

export class DispatchTrip {
  id: number;
  status: TripStatus;
  startCity: string;
  startState: string;
  endCity: string;
  endState: string;
  scheduledStartDateTime: Date;
  scheduledEndDateTime: Date;
  actualStartDateTime?: Date;
  actualEndDateTime?: Date;
  pickupCount: number;
  deliveryCount: number;
  truck: DispatchTruck;
  trailer: DispatchTrailer;
  loadNumbers: Array<string>;
  isMissingDetails: boolean;
  isLate?: boolean;
  stops: Array<DispatchStop>;

  constructor(id?: number) {
    this.id = id;
  }
}
