import React, { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shamrock-clover-ui';
import { MenuItem, ListItemIcon, ListItemText, Button, ListSubheader } from '@mui/material';
import css from './styles/compliance-menu.scss';
import { ClearAll, VisibilityOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/configureStore';
import { setComplianceFilters, setNotificationStatus } from '../../redux/actions/notificationsActions';
import { ComplianceRow } from '../../redux/reducers/notificationsReducer';
import PillComponent from '../core/pill-component';
import { Divider, Menu } from 'drift-ui';
import { GridRowId } from 'drift-ui/dist/drift/components/datagrid/DataGrid';
import { Guid } from 'guid-typescript';
import { FilterInput } from 'shamrock-clover-ui/dist/clover/components/FilterInput/FilterInput';
import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import NotificationService from '../../services/notification.service';

interface Props {
  rows: ComplianceRow[];
  selectedRowIds: GridRowId[];
  clearSelection: () => void;
}
const NotificationsComplianceMenu: React.FC<Props> = ({ rows, selectedRowIds, clearSelection }: Props) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterOptionsAnchorEl, setFilterOptionsAnchorEl] = React.useState(null);
  const searchText = useAppSelector((state) => state.notifications.compliance.filters.searchText);
  const searchType = useAppSelector((state) => state.notifications.compliance.filters.searchType);
  const warningType = useAppSelector((state) => state.notifications.compliance.filters.warningType);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(null);
  const openMenu = Boolean(anchorEl);
  const openFiltersMenu = Boolean(filterOptionsAnchorEl);
  const filterOptions = [t('expired'), t('30_days'), t('60_days')];
  const warningTypeOptions: MenuOption[] = [
    {
      optionName: t('all_warnings'),
    },
    {
      optionName: t('Driver'),
    },
    {
      optionName: t('Truck'),
    },
    {
      optionName: t('trailer'),
    },
  ];

  const [selectedWarningType, setSelectedWarningType] = useState<MenuOption>(warningTypeOptions[0]);
  const notificationService = new NotificationService();

  const filterOptionsSelected = (index: number) => {
    if (index === selectedFilterIndex) {
      setSelectedFilterIndex(null);
      dispatch(setComplianceFilters({ searchText, searchType: '', warningType }));
    } else {
      const option = filterOptions[index];
      setSelectedFilterIndex(index);
      dispatch(setComplianceFilters({ searchText, searchType: option, warningType }));
    }
    handleFilterOptionsClose();
  };

  const clearFilters = () => {
    dispatch(setComplianceFilters({ searchText: '', searchType: '', warningType: '' }));
    setSelectedWarningType(warningTypeOptions[0]);
    setSelectedFilterIndex(null);
  };

  const filterRowsBySearchText = (event: FormEvent<HTMLInputElement>) => {
    const text = event.currentTarget.value;
    dispatch(setComplianceFilters({ searchText: text, searchType, warningType }));
  };

  const handleBulkActionClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkActionClose = () => {
    setAnchorEl(null);
  };

  const handleFilterOptionsClick = (event: any) => {
    setFilterOptionsAnchorEl(event.currentTarget);
  };

  const handleFilterOptionsClose = () => {
    setFilterOptionsAnchorEl(null);
  };

  async function markSelectedAsViewed() {
    dispatch(setNotificationStatus(selectedRowIds as number[], 'Read'));
    clearSelection();
    await Promise.all(selectedRowIds.map((rowId) => notificationService.markNotificationAsRead(rowId as number)));
  }

  async function markAllAsViewed() {
    dispatch(
      setNotificationStatus(
        rows.map((row) => row.id),
        'Read',
      ),
    );
    clearSelection();
    await Promise.all(rows.map((row) => notificationService.markNotificationAsRead(row.id)));
  }

  return (
    <div className={css.toolbar}>
      <div className={css.inputs}>
        <span className={css.label}>{`${t('warning_type')}:`}</span>
        <PillComponent
          dropdownDownWidth={i18n.language === 'es' ? 125 : 100}
          selectedOption={selectedWarningType}
          options={warningTypeOptions}
          onOptionSelected={(selectMenuOption) => {
            setSelectedWarningType(selectMenuOption);
            dispatch(setComplianceFilters({ searchText, searchType, warningType: selectMenuOption.optionName }));
          }}
          rightComponent={
            <FilterInput
              height="30px"
              width="180px"
              value={searchText}
              onChange={(e) => filterRowsBySearchText(e)}
              clearSearch={() => dispatch(setComplianceFilters({ searchText: '', searchType, warningType }))}
              placeholder={t('search_cdl_driver')}
              className={css.textInput}
              icon={searchText && 'Close'}
            />
          }
          showDivider={true}
        />
      </div>
      <div className={css.buttonsSection}>
        <Button
          className={css.button}
          id="basic-button"
          aria-controls={openMenu ? 'bulk-actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={handleBulkActionClick}
        >
          <Icon icon="threeDots" size={'20'} color={'black'} />
          {t('Bulk Actions')}
        </Button>
        <Menu anchorEl={anchorEl} id="bulk-actions-menu" open={openMenu} onClose={handleBulkActionClose}>
          <MenuItem onClick={markSelectedAsViewed}>
            <ListItemIcon>
              <VisibilityOutlined fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{t('marked_as_viewed')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={markAllAsViewed}>
            <ListItemIcon>
              <ClearAll fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{t('mark_all_as_viewed')}</ListItemText>
          </MenuItem>
        </Menu>
        <Button
          className={css.button}
          id="basic-button"
          aria-controls={openFiltersMenu ? 'grouped-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFiltersMenu ? 'true' : undefined}
          onClick={handleFilterOptionsClick}
        >
          <Icon icon="filter" size={'20'} color={'black'} />
          {t('filters')}
        </Button>
        <Menu
          id="grouped-menu"
          anchorEl={filterOptionsAnchorEl}
          open={openFiltersMenu}
          onClose={handleFilterOptionsClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <ListSubheader>
            <div>
              {t('expires_within')}
              <Divider />
            </div>
          </ListSubheader>
          {filterOptions.map((option, index) => (
            <MenuItem
              selected={selectedFilterIndex === index}
              key={Guid.create().toString()}
              onClick={() => filterOptionsSelected(index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
        <span className={css.resetAll} onClick={() => clearFilters()} onKeyDown={() => clearFilters()}>
          {t('reset_all')}
        </span>
      </div>
    </div>
  );
};
export default NotificationsComplianceMenu;
