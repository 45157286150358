import * as AppConstants from '../../constants/appConstants';
import * as contentful from 'contentful';
import { CompanyService, FuelService, LoadManagementService } from '../../services';
import { Company, User } from '../../interfaces';
import { TMSCompany } from '../../interfaces/tms-company';
import Token from '../../interfaces/token';
import SnackBarConfig from '../../interfaces/snack-bar-config';
import { FinancialUser } from '../../interfaces/financial-user';
import { JoyrideKey } from '../../redux/reducers/appReducer';
import { Dispatch } from 'redux';

const Config = require('Config');

let gettingStates = false;
let gettingCountries = false;

export function getCompany(email: string) {
  return async (dispatch: Dispatch) => {
    const company = await new CompanyService().getCompany(email);
    dispatch(setCompany(company[0]));
    return company;
  };
}

export function setCompany(company: Company) {
  return { type: AppConstants.SET_COMPANY, company };
}

export function getTMSCompany(mongoCompanyId: string) {
  return async (dispatch: Dispatch) => {
    const company = await new LoadManagementService().getCompany(mongoCompanyId);
    dispatch(setTMSCompany(company));
    return company;
  };
}

export function setTMSCompany(company: TMSCompany) {
  return { type: AppConstants.SET_TMS_COMPANY, tmsCompany: company };
}

export function getStateList() {
  return async (dispatch, getState) => {
    const list = getState().app.stateList;
    if ((!list || list.length === 0) && !gettingStates) {
      gettingStates = true;
      const stateList = await new FuelService().getStates();
      dispatch(setStateList(stateList));
      gettingStates = false;
    }
  };
}

export function getCountries() {
  return async (dispatch, getState) => {
    const list = getState().app.countries;
    if ((!list || list.length === 0) && !gettingCountries) {
      gettingCountries = true;
      const countries = await new LoadManagementService().getCountries();
      dispatch(setCountries(countries));
      gettingCountries = false;
    }
  };
}

export function getMaintenanceAlert() {
  return async (dispatch: Dispatch) => {
    const client = contentful.createClient(Config.contentfulOptions);
    const alertItem = await client.getEntry(Config.contentfulMaintenanceAlertId);
    const maintenanceAlert = {
      title: alertItem.fields.title,
      subtitle: alertItem.fields.subtitle,
      message: alertItem.fields.message,
      image: alertItem.fields.image['fields'].file.url,
      visible: alertItem.fields.showInApp,
    };

    dispatch(setMaintenanceAlert(maintenanceAlert));
  };
}

export function setStateList(stateList: []) {
  return { type: AppConstants.SET_STATE_LIST, stateList };
}

export function setCountries(countries: []) {
  return { type: AppConstants.SET_COUNTRIES, countries };
}

export function setFeatures(features: []) {
  return { type: AppConstants.SET_FEATURES, features };
}

export function setMaintenanceAlert(maintenanceAlert) {
  return { type: AppConstants.SET_MAINTENANCE_ALERT, maintenanceAlert };
}

export function setUser(user: User) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: AppConstants.SET_USER, user });
  };
}

export function setClientNumber(clientNumber: string) {
  return { type: AppConstants.SET_CLIENT_NUMBER, clientNumber };
}

export function setFinancialUser(financialUser: FinancialUser) {
  return { type: AppConstants.SET_FINANCIAL_USER, financialUser };
}

export function setToken(token: Token) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: AppConstants.SET_TOKEN, token });
  };
}

export function setShowSnackBar(value: boolean) {
  return { type: AppConstants.SET_SHOW_SNACK_BAR, enabled: value };
}

export function setShowWait(value: boolean) {
  return { type: AppConstants.SET_SHOW_WAIT, enabled: value };
}

export function showSimpleSnackBar(
  message: string,
  type?: 'error' | 'info' | 'undo' | undefined,
  time?: number,
  callback?: (close: Function) => void,
) {
  const snackBarConfig = { message, type, time, callback } as SnackBarConfig;
  return {
    type: AppConstants.SHOW_SIMPLE_SNACK_BAR,
    enabled: true,
    snackBarConfig,
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch(setUser(undefined));
    dispatch(setToken({}));
  };
}

export function setJoyrideIntro(joyrideIntro: boolean) {
  return { type: AppConstants.SET_JOYRIDE_INTRO, joyrideIntro };
}

export function setSeenJoyride(joyride: JoyrideKey, value: boolean) {
  return { type: AppConstants.SET_SEEN_JOYRIDE, joyride, value };
}

export function setShellNavigationPath(path: string) {
  return { type: AppConstants.SET_SHELL_NAVIGATION_PATH, path };
}
