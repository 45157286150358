import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openDialog: null,
  prevDialog: null,
};

const modalSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    closeDialog: (state) => {
      state.openDialog = null;
    },
    prevPage: (state, action) => {
      state.prevDialog = action.payload;
    },
  },
});

export const { openDialog, closeDialog, prevPage } = modalSlice.actions;
export default modalSlice.reducer;
