import classNames from 'classnames';
import React from 'react';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import css from './styles/nav-link-bar.scss';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { Badge } from '@mui/material';

export interface NavItem {
  title: string;
  index: number;
  link: string;
  joyrideId?: string;
  badge?: number;
}

interface Props {
  currentIndex: number;
  items: NavItem[];
  handleClick: Function;
  warningTab?: number;
  warningTabs?: number[];
}

function NavLinkBar({ currentIndex, items, handleClick, warningTab, warningTabs }: Props) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {items.map((item) => (
        <NavLink
          key={item.index}
          to={item.link}
          className={classNames(
            css.navItem,
            { [css.active]: currentIndex === item.index },
            {
              [css.warning]:
                item.index === warningTab ||
                (warningTabs && warningTabs.length > 0 && warningTabs.find((tab) => tab === item.index) !== undefined),
            },
          )}
          onClick={() => handleClick(item.index)}
          data-testid={item.title.toLowerCase().replace(/ /g, '-') + '-header'}
          data-joyrideid={item?.joyrideId}
        >
          <div style={{ display: 'flex', alignItems: 'center', minWidth: 'max-content', gap: '4px' }}>
            <span>{t(item.title)}</span>
            {item.badge && item.badge > 0 ? (
              <div className={css.badge}>
                <Badge
                  badgeContent={item.badge}
                  showZero={false}
                  sx={{
                    position: 'static',
                    '& .MuiBadge-badge': {
                      backgroundColor: '#333333',
                      color: '#ffffff',
                      fontSize: '13px',
                      fontWeight: 'bold',
                      minWidth: '20px',
                      height: '20px',
                      padding: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      transform: 'none',
                    },
                  }}
                />
              </div>
            ) : null}
          </div>
          {(item.index === warningTab ||
            (warningTabs && warningTabs.length > 0 && warningTabs.find((tab) => tab === item.index) !== undefined)) &&
            currentIndex !== item.index && <WarningIcon />}
        </NavLink>
      ))}
    </Fragment>
  );
}

export default NavLinkBar;
