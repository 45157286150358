import { Observation } from '../../interfaces/observation';
import * as NotificationsConstants from '../../constants/notificationsConstants';

export interface ComplianceRow {
  id: number;
  type: string;
  entityId: number;
  expiresIn?: number;
  profile: string;
  description: string;
  status: string;
}

export interface ComplianceFilters {
  searchText: string;
  searchType: string;
  warningType: string;
}

const initialState = {
  wex: [] as Notification[],
  compliance: {
    rows: [] as ComplianceRow[],
    isLoading: true,
    filters: {
      searchText: '',
      searchType: '',
      warningType: '',
    } as ComplianceFilters,
  },
  observations: [] as Observation[],
};

export default function notificationsReducer(state = initialState, action: { type: string; payload: any }) {
  let newState = state;
  switch (action.type) {
    case NotificationsConstants.SET_COMPLIANCE_LOADING:
      return { ...newState, compliance: { ...newState.compliance, isLoading: action.payload } };
    case NotificationsConstants.SET_COMPLIANCE_ROWS:
      return { ...newState, compliance: { ...newState.compliance, rows: action.payload } };
    case NotificationsConstants.SET_COMPLIANCE_FILTERS:
      return { ...newState, compliance: { ...newState.compliance, filters: action.payload } };
    case NotificationsConstants.SET_NOTIFICATION_STATUS:
      return {
        ...newState,
        compliance: {
          ...newState.compliance,
          rows: newState.compliance.rows.map((row) => {
            if (action.payload.ids.includes(row.id)) {
              return { ...row, status: action.payload.status };
            }
            return row;
          }),
        },
      };
    case NotificationsConstants.SET_WEX_NOTIFICATIONS:
      return { ...newState, wex: action.payload };
    case NotificationsConstants.SET_OBSERVATIONS:
      return { ...newState, observations: action.payload };
    default:
      return state;
  }
}
