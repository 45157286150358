import http from '../../../utils/axios-utils';
import { Debtor } from 'rtspro-financial-core-module';

const Config = require('Config');

export class DebtorService {
  serviceUrl: string;

  constructor() {
    this.serviceUrl = Config.debtorServiceUrl;
  }

  async getDebtors(mcNumbers: string[]): Promise<Debtor[]> {
    const filteredMcNumbers = mcNumbers.filter((mc) => typeof mc === 'string' && /^\d+$/.test(mc));
    const batchSize = 25;

    const batchedMcNumbers = [];
    for (let i = 0; i < filteredMcNumbers.length; i += batchSize) {
      const batch = filteredMcNumbers.slice(i, i + batchSize);
      const commaDelimmedBatch = batch.join(',');
      batchedMcNumbers.push(commaDelimmedBatch);
    }

    const promises = batchedMcNumbers.map((mcNumberBatch) => {
      return http.get<Debtor[], unknown>(`${this.serviceUrl}debtors?mcNumbers=${mcNumberBatch}`)();
    });

    const results = await Promise.all(promises);
    const debtors = results.flatMap((result) => result.data);

    return debtors;
  }
}
