import { Observation } from '../interfaces/observation';
import Service from './service';

const Config = require('Config');
export default class ObserverService extends Service {
  constructor() {
    super(Config.observerServiceUrl);
  }

  async getObservations(entityId?: number, entityType?: string, entityProperty?: string): Promise<Observation[]> {
    const config = {
      params: {
        entityId: entityId,
        entityName: entityType,
        entityProperty: entityProperty,
      },
    };

    const response = await this.axios.get('observations', config);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
