import { getAxiosInstance } from '../services/service';

const get =
  <T extends unknown, Q extends unknown>(url: string) =>
  async (query?: Q) => {
    const axios = getAxiosInstance(url);
    return await axios.get<T>('', { params: query });
  };

const post =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body?: B) => {
    const axios = getAxiosInstance(url);
    return await axios.post<T>('', body);
  };

const put =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body?: B) => {
    const axios = getAxiosInstance(url);
    return await axios.put<T>('', body);
  };

const patch =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body?: B) => {
    const axios = getAxiosInstance(url);
    return await axios.patch<T>('', body);
  };

const doDelete =
  <T extends unknown, Q extends unknown>(url: string) =>
  async (query?: Q) => {
    const axios = getAxiosInstance(url);
    return await axios.delete<T>('', { params: query });
  };

const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const http = { get, put, post, patch, delete: doDelete, delay };

export default http;
